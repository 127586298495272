import { ReactNode, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FocusRingVariants, FontSizes, FontWeights, Modal, Notice, Text } from '@calm-web/design-system';

import Loader from '@/components/Loader';
import PaymentDetailsModal from '@/components/PreSignupFlow/Components/PaymentDetailsModal';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { PaymentScreenConfig } from '@/components/SimplifiedSignup/Payment/types';
import paymentFormMessages from '@/components/purchase/CreditCardForm/messages';
import { SecureTransactionContainer } from '@/components/purchase/CreditCardForm/styles';
import LoggedInAs from '@/components/purchase/LoggedInAs';
import PaymentError from '@/components/purchase/PaymentError';
import { SubscribePurchaseError, subscribePurchaseErrors } from '@/components/purchase/utils/Purchase';
import { getPurchaseError } from '@/components/purchase/utils/getPurchaseError';
import { useAnalytics } from '@/hooks/analytics';
import { LegacyCalmError } from '@/hooks/api/types';
import { useGetPurchaseTerms } from '@/hooks/dtcLeadGenPartnership/useGetPurchaseTerms';
import { useFormattedCurrency } from '@/hooks/purchase';
import { usePurchaseSuccess } from '@/hooks/purchase/usePurchaseSuccess';
import {
	useAppLoadedState,
	useDeviceState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import { useSubscriptionDate } from '@/hooks/user';
import { setUser } from '@/store/user/actions';
import isUSPricing from '@/utils/prices/isUSPricing';
import { userCanTrial } from '@/utils/subscriptions';

import PaymentTypeSelector from '../PaymentTypeSelector';
import PlanSkuToggle from '../PlanSkuToggle';
import PurchaseDetails from '../PurchaseDetails/refreshedPurchaseDetails';
import purchaseMessages from '../messages';
import messages from './messages';
import {
	CTAContainer,
	HelpButton,
	HelpIcon,
	NoticeWrapper,
	PurchaseFormSectionWrapper,
	Wrapper,
	LockIcon,
	SectionHeader,
	SubmitButton,
	StyledCreditCardForm,
} from './styles';
import {
	BEFORE_PURCHASE_STATE,
	OnGenericPaymentSubmitRefresh,
	OnPurchaseCancel,
	OnPurchaseError,
	OnPurchaseSuccess,
	PaymentType,
	PURCHASED_STATE,
	PurchaseFormProps,
	PurchaseState,
	PURCHASING_STATE,
	RequestInfo,
} from './types';

const PurchaseFormRefresh = ({
	buttonBgColor = 'buttonBlue',
	buttonTextColor,
	onSuccess: _onSuccess,
	ctaCopy,
	hideTerms,
	showLoggedInAs,
	purchaseTerms,
	isDisabled = false,
	onLogout,
	onPurchase,
	productInfo,
	hidePriceAfterDisclaimer = true,
	centerFormElements = false,
	analyticsPrefix = 'Subscribe : Purchase : Form',
	children,
	initialNameValue = '',
	ignoreSubscribeErrors = false,
	updateUserOnSuccess = true,
	hideAlternativePaymentMethods = false,
	hidePaypal = false,
	hideApplePay = false,
	alignment = 'left',
	ignoreSuccessLogging = false,
	isSecondFreeTrial = false,
	hideBillingAddressFields = false,
	terms,
	...props
}: PurchaseFormProps) => {
	const _email = props.email ?? null;
	const purchaseParams = usePurchaseParamsState();
	const { purchaseType } = purchaseParams;
	const appLoaded = useAppLoadedState();
	const user = useUserState();
	const prices = usePricesState();
	const device = useDeviceState();

	const dispatch = useDispatch();
	const { formatMessage, formatDate } = useIntl();
	const { logEvent } = useAnalytics();
	const logPurchaseSuccess = usePurchaseSuccess();
	const partner = usePartnerState();
	const partnerPurchaseTerms = useGetPurchaseTerms(partner?.offer_details);
	const [showSamsungRedirect, setShowSamsungRedirect] = useState(false);
	const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);
	const isFreeTrial = (userCanTrial(user) && purchaseType?.type === 'freetrial') || purchaseType?.isFreeTrial;
	const { activeScreenConfig, isOrganicTraffic = true } = useSimplifiedSignupContext();
	const { expireDate, premiumSubscriptionDaysRemaining } = useSubscriptionDate();
	const {
		terms: configTerms,
		offerTerms,
		showToggle: configShowTerms = true,
		trialTerms = messages.freeTrialTerms,
		noTrialTerms = messages.noTrialTerms,
		paymentFormHeader,
		paymentFormSubHeader,
		isRenewalOffer,
	} = activeScreenConfig as PaymentScreenConfig;
	const showToggle = configShowTerms && isOrganicTraffic;

	const [purchaseState, setPurchaseState] = useState<PurchaseState>(BEFORE_PURCHASE_STATE);
	const [firstName, setFirstName] = useState(initialNameValue);
	const [currentPaymentType, setCurrentPaymentType] = useState<PaymentType | null>(
		hideAlternativePaymentMethods ? 'credit_card' : null,
	);
	const [errorCopy, setErrorCopy] = useState<string | null>('');
	const [isLoading, setIsLoading] = useState(false);
	const [noSubscribeError] = useState(
		ignoreSubscribeErrors
			? false
			: subscribePurchaseErrors
					.filter(value => (isSecondFreeTrial ? value !== 'previously-trialed' : true))
					.includes(purchaseParams?.purchaseType?.type as SubscribePurchaseError),
	);
	const formatCurrency = useFormattedCurrency();
	const isUSPrice = isUSPricing(prices, device);

	useEffect(() => {
		setIsLoading(purchaseState === PURCHASING_STATE || purchaseState === PURCHASED_STATE);
	}, [purchaseState]);

	const onPurchaseError: OnPurchaseError = err => {
		setPurchaseState(BEFORE_PURCHASE_STATE);
		if (typeof err === 'string') {
			setErrorCopy(err);
		}
		const displayError = err as LegacyCalmError;
		setErrorCopy(formatMessage(getPurchaseError(displayError)));
		if (displayError.code === 'promotion_already_redeemed') {
			// Samsung-specific promotion error
			// show a link to the regular subscribe flow if this is the case
			setShowSamsungRedirect(true);
		}
	};

	const onPurchaseCancel: OnPurchaseCancel = (err, paymentType) => {
		setPurchaseState(BEFORE_PURCHASE_STATE);
		logEvent({
			eventName: `${analyticsPrefix} : Cancelled`,
			eventProps: {
				error: err,
				payment_type: paymentType,
			},
		});
		setCurrentPaymentType(null);
	};

	const onPurchaseSubmit: OnGenericPaymentSubmitRefresh = args => {
		setPurchaseState(PURCHASING_STATE);
		return onPurchase(args);
	};

	const onPurchaseSuccess: OnPurchaseSuccess = async (subscription, selectedPaymentType) => {
		setShowPurchaseSuccess(true);
		setPurchaseState(PURCHASED_STATE);
		const newUser = {
			...user,
			...subscription,
			id: user?.id,
			subscription,
		};
		if (updateUserOnSuccess) {
			dispatch(setUser(newUser));
		}

		if (!ignoreSuccessLogging) {
			await logPurchaseSuccess({
				analyticsPrefix,
				newUser,
				paymentType: selectedPaymentType,
				productInfo,
			});
		}

		_onSuccess();
	};

	const createRequestInfo = (): RequestInfo => {
		const email = _email || user?.email;
		return {
			name: firstName,
			...(email ? { email } : {}),
		};
	};

	let showCCForm = currentPaymentType === 'credit_card';

	if (showSamsungRedirect) {
		showCCForm = false;
	}

	const getButtonText = (): string => {
		if (ctaCopy) {
			return ctaCopy;
		}

		if (isFreeTrial && purchaseState === BEFORE_PURCHASE_STATE) {
			return formatMessage(messages.startFreeTrial);
		}
		// All other subscribe cases
		if (purchaseState === BEFORE_PURCHASE_STATE) {
			return formatMessage(messages.useCard);
		}

		if (purchaseState === PURCHASED_STATE) {
			return formatMessage(messages.success);
		}

		return '';
	};

	function onTooltipClick(): void {
		setIsModalOpen(true);
		logEvent({
			eventName: 'Subscribe : Purchase : Form : Tool Tip : Clicked',
			eventProps: {
				tool_tip: 'why now',
			},
		});
	}

	function getTermsText(): MessageDescriptor {
		return prices.current[purchaseParams?.plan || 'yearly'] !==
			prices.original[purchaseParams?.plan || 'yearly'] && offerTerms
			? offerTerms
			: noTrialTerms;
	}

	if (!appLoaded) return <Loader />;

	if (noSubscribeError) {
		return <PaymentError errorType={purchaseParams?.purchaseType?.type as SubscribePurchaseError} />;
	}

	return (
		<Wrapper $alignment={alignment} id="purchase-form">
			<PurchaseFormSectionWrapper>
				{isUSPrice && showToggle ? (
					<PlanSkuToggle terms={terms} />
				) : (
					<>
						<Text el="p" size={FontSizes.lg} weight={FontWeights.Medium}>
							{formatMessage(paymentFormHeader ?? messages.confirmPlanNoToggle, {
								trialLengthInDays: purchaseParams?.purchaseType?.duration || 14,
							})}
						</Text>
						<Text el="p" size={FontSizes.base}>
							{formatMessage(paymentFormSubHeader ?? messages.confirmSubtitle)}
						</Text>
						<PurchaseDetails isRenewalOffer={isRenewalOffer} />
						{configTerms && (
							<Text size={FontSizes.sm} color="blackAlpha60" el="p">
								{formatMessage(configTerms, {
									price: formatCurrency(prices.current[purchaseParams?.plan || 'yearly']),
									cancelAnytimeLink: (...chunks: ReactNode[]) => (
										<a
											href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
											target="_blank"
											rel="noreferrer"
										>
											{chunks}
										</a>
									),
									originalPrice: formatCurrency(prices.original[purchaseParams?.plan || 'yearly']),
									expireDate:
										expireDate &&
										formatDate(expireDate, {
											month: '2-digit',
											year: 'numeric',
										}),
								})}
							</Text>
						)}
					</>
				)}
			</PurchaseFormSectionWrapper>
			<PurchaseFormSectionWrapper>
				<SectionHeader>
					<Text size={FontSizes.lg} weight={FontWeights.Medium}>
						{formatMessage(messages.paymentMethodHeader)}
					</Text>
					{isFreeTrial && (
						<HelpButton
							aria-label={formatMessage(purchaseMessages.cvvHelpModalTitle)}
							onPress={onTooltipClick}
							Icon={HelpIcon}
							focusRingVariant={FocusRingVariants.Dark}
						/>
					)}
				</SectionHeader>
				{!hideAlternativePaymentMethods && (
					<div>
						<PaymentTypeSelector
							currentPaymentType={currentPaymentType}
							setCurrentPaymentType={setCurrentPaymentType}
							onPurchaseSuccess={onPurchaseSuccess}
							onPurchaseError={onPurchaseError}
							onPurchaseSubmit={onPurchaseSubmit}
							onPurchaseCancel={onPurchaseCancel}
							requestInfo={createRequestInfo()}
							isLoading={isLoading}
							isDisabled={isDisabled}
							hidePaypal={hidePaypal}
							hideApplePay={hideApplePay}
							setErrorCopy={setErrorCopy}
							showHeading={false}
							isMonthlyPricingTest={true}
						/>
					</div>
				)}
				{showCCForm && (
					<StyledCreditCardForm
						externalSubmit={true}
						buttonBgColor={buttonBgColor}
						buttonTextColor={buttonTextColor}
						ctaCopy={ctaCopy}
						hideTerms={hideTerms}
						hidePriceAfterDisclaimer={hidePriceAfterDisclaimer}
						centerFormElements={true}
						analyticsPrefix={analyticsPrefix}
						purchaseState={purchaseState}
						firstName={firstName}
						setFormIsValid={setFormIsValid}
						setFirstName={setFirstName}
						setErrorCopy={setErrorCopy}
						requestInfo={createRequestInfo()}
						onPurchaseSubmit={onPurchaseSubmit}
						onPurchaseError={onPurchaseError}
						onPurchaseSuccess={onPurchaseSuccess}
						purchaseTerms={purchaseTerms || ''}
						isDisabled={isDisabled}
						setCurrentPaymentType={setCurrentPaymentType}
						hideBillingAddressFields={hideBillingAddressFields}
					>
						{children}
					</StyledCreditCardForm>
				)}
			</PurchaseFormSectionWrapper>
			{children}
			<CTAContainer>
				<Text size={FontSizes.sm} color="blackAlpha60" el="p">
					{partner ? (
						<>
							{partnerPurchaseTerms}
							<br />
							<br />
							{partner?.offer_details?.terms || ''}
						</>
					) : isFreeTrial ? (
						<div>
							{formatMessage(trialTerms, {
								price: formatCurrency(prices.current[purchaseParams?.plan || 'yearly']),
								originalPrice: formatCurrency(prices.original[purchaseParams?.plan || 'yearly']),
								cancelAnytimeLink: (...chunks: ReactNode[]) => (
									<a
										href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
										target="_blank"
										rel="noreferrer"
									>
										{chunks}
									</a>
								),
								a: (...chunks: ReactNode[]) => (
									<a href="/terms" target="_blank">
										{chunks}
									</a>
								),
							})}
						</div>
					) : (
						formatMessage(getTermsText(), {
							price: formatCurrency(prices.current[purchaseParams?.plan || 'yearly']),
							cancelAnytimeLink: (...chunks: ReactNode[]) => (
								<a
									href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
							cadence: formatMessage(messages[purchaseParams?.plan || 'yearly']),
							originalPrice: formatCurrency(prices.original[purchaseParams?.plan || 'yearly']),
							premiumSubscriptionDaysRemaining,
							expireDate:
								expireDate &&
								formatDate(expireDate, {
									dateStyle: 'short',
								}),
						})
					)}
				</Text>
				<SubmitButton
					fullWidth
					backgroundColor="buttonBlue"
					focusRingVariant={FocusRingVariants.Dark}
					textColor="white"
					type="submit"
					isDisabled={!formIsValid}
					isLoading={isLoading}
					data-testid="subscribe-submit-btn"
					form="credit_card_form"
				>
					{getButtonText()}
				</SubmitButton>
				<SecureTransactionContainer>
					<LockIcon aria-hidden data-testid="lock-icon" />
					<Text el="p" size={FontSizes.sm} weight={FontWeights.Medium} color="gray5">
						{formatMessage(paymentFormMessages.secureTransaction)}
					</Text>
				</SecureTransactionContainer>
			</CTAContainer>
			{showLoggedInAs && <LoggedInAs onLogout={onLogout} email={_email} />}
			{showPurchaseSuccess && (
				<NoticeWrapper>
					<Notice isSuccess onClick={() => setShowPurchaseSuccess(false)} describedBy="purchase-form">
						{formatMessage(messages.success)}
					</Notice>
				</NoticeWrapper>
			)}
			{errorCopy && (
				<NoticeWrapper>
					<Notice isSuccess={false} onClick={() => setErrorCopy(null)} describedBy="purchase-form">
						{errorCopy}
					</Notice>
				</NoticeWrapper>
			)}
			<Modal isOpen={isModalOpen} closeModal={(): void => setIsModalOpen(false)} canClose aria-label={''}>
				<PaymentDetailsModal onCloseClick={(): void => setIsModalOpen(false)} />
			</Modal>
		</Wrapper>
	);
};

export default PurchaseFormRefresh;
