import Link from 'next/link';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette } from 'styled-tools';

import {
	Button,
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	maxWidth,
	minWidth,
	Text,
} from '@calm-web/design-system';

import { PageWrap } from '../styles';

export const SimplifiedSignupHandoffContainer = styled(PageWrap)`
	max-width: 1200px;
	margin: auto;
`;

export const HandoffWelcomeContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	> h1 {
		margin: 0;
	}

	${maxWidth('tablet')} {
		margin-top: 30px;
	}
`;

export const ValuePropsWrapper = styled.div`
	max-width: 560px;
	margin: auto;
	margin-left: 20px;
	margin-right: 20px;

	${minWidth('tablet')} {
		margin: auto;
		margin-top: 40px;
	}

	h3 {
		margin-bottom: 20px;
	}
`;

export const AppDownloadContainer = styled.div`
	flex-direction: column;
	display: flex;
	margin-top: 20px;
	gap: 44px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 0 !important;

	${minWidth('tablet')} {
		flex-direction: row;
		max-height: 560px;
		margin-top: 30px;
		margin-left: 0;
		margin-right: 0;
	}
`;
export const AppDownloadItemContainer = styled.div<{ isHidden?: boolean }>`
	${ifProp(
		'isHidden',
		css`
			display: none;
		`,
	)}
	background: white;
	border-radius: 20px;
	overflow: hidden;
	flex: 1;

	${minWidth('tablet')} {
		margin-bottom: 40px;
		height: 560px;
	}
`;

export const Line = styled.div`
	height: 1px;
	margin-top: 40px;

	/* Solids/Gray 3 */

	background: ${palette('gray3')};
	transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const CTAWrapper = styled.div<{ isWithinModal?: boolean }>`
	text-align: center;
	${ifNotProp('isWithinModal', 'margin-bottom: 80px;')}
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 40px;

	${minWidth('tablet')} {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const CTALink = styled(Link)`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)};
`;

export const QRCodeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 350px;
	padding: 1rem 0;
	margin: auto;
	height: 100%;
`;

export const SocialWrapper = styled.div`
	> * {
		margin-left: 6px;
		margin-right: 6px;
	}
`;

export const QRCode = styled.div`
	margin-top: 30px;
	margin-bottom: 40px;
	padding: 10px;
	border: 1px solid ${palette('gray2')};
	border-radius: 20px;
`;

export const AppImageWrapper = styled.div`
	aspect-ratio: 67 / 112;
	position: relative;
	max-width: 188.603px;
	max-height: 414.134px;
	height: 100%;
	width: 100%;
	margin: auto;
	margin-top: 36px;

	${minWidth('tablet')} {
		max-width: 335.28px;
		max-height: 642.13px;
		margin-top: 60px;
	}
`;

export const IconButton = styled(Button)`
	margin-top: 30px;
	margin-bottom: 12px;
	height: 40px;
	width: auto;

	${minWidth('desktop')} {
		margin-left: 6px;
		margin-right: 6px;
	}
`;

export const HandoffSubheading = styled(Text)`
	text-align: center;
	max-width: 560px;
	margin: 0;
`;
